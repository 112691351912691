<template>
  <div class="container">
    <v-flex>
      <v-row>
        <v-col cols="12" lg="8" md="8" sm="12" xs="12" class="mt-10">
          <h2 class="blue--text ">لم يتم العثور علي نتائج بحث</h2>
          <h4 class="black--text ">
            لم يمكن العثور على الصفحة التي طلبتها.  استعمل شريط التصفح أعلاه
            و شاهد المقترحات للعثور على المقال .
          </h4>
      
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="12" xs="12">
            <ProjectAndTenders></ProjectAndTenders>
        </v-col>
      </v-row>
    
    </v-flex>
  </div>
</template>
<script>
import ProjectAndTenders from '../components/projectAndTenders.vue'
export default {
  components: { ProjectAndTenders },
}
</script>
<style>
.imgClosed{

  margin-right: 200px;
}
</style>